<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">수행도평가</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="거래정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <!--            <tr>-->
            <!--              <th>업체명</th>-->
            <!--              <td colspan="3">{{ itemData.companyName }}</td>-->
            <!--              <th>발주명</th>-->
            <!--              <td colspan="3">{{ evaluateResultData.model.title }}</td>-->
            <!--            </tr>-->
            <tr>
              <th>평가일</th>
              <td>{{ model.regDate | dateStringFormat }}</td>
              <th>평가자</th>
              <td>{{ model.regId }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
      <div class="head_section">
        <h4 class="tit_section">평가 결과</h4>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 724px" />
          </template>
          <template v-slot:body>
            <tr v-for="(quest, qIdx) in model.questList" :key="`quest_${qIdx}`">
              <th>{{ qIdx + 1 }}</th>
              <td>
                <p class="desc_view">{{ quest.question }}</p>
                <template v-if="quest.optionList.length > 0">
                  <template v-if="quest.optionType === 'S'">
                    <PerformanceRadio
                      :name="`${qIdx}_${quest.optionList[0].optionNum}`"
                      :dataList="quest.optionList"
                      :selectedId.sync="quest.value"
                      :qIdx="qIdx"
                      :isDisabled="true"
                    />
                  </template>
                  <template v-else-if="quest.optionType === 'M'">
                    <Input :value.sync="quest.value" :maxLength="50" :isDisabled="true" />
                  </template>
                </template>
                <template v-else>
                  <p class="desc_view">등록된 항목이 없습니다.</p>
                </template>
              </td>
            </tr>
          </template>
        </TableViewWithTitle>
        <TableViewWithTitle
          v-if="evaluateData.exEvaluateList && evaluateData.exEvaluateList.length > 0"
          title="평가내역"
        >
          <template v-slot:colgroup>
            <col style="width: 100px" />
            <col style="width: 120px" />
            <col style="width: 80px" />
            <col style="width: 616px" />
          </template>
          <template v-slot:header>
            <tr class="tr_eval">
              <th>평가일자</th>
              <th>평가자</th>
              <th>총점</th>
              <th>사유</th>
            </tr>
          </template>
          <template v-slot:body>
            <tr v-for="(item, idx) in evaluateData.exEvaluateList" :key="idx">
              <td class="align_center">{{ item.regDate | dateStringFormat }}</td>
              <td class="align_center">{{ item.regId }}</td>
              <td class="align_center">{{ item.totalScore }}</td>
              <td>{{ item.reason }}</td>
            </tr>
          </template>
        </TableViewWithTitle>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickGoToList">목록</button>
      </div>
      <div class="area_right">
        <button type="button" class="btn_fourthly btn_large" @click="onClickModify">재평가</button>
        <!-- <button type="button" class="btn_secondary btn_large" @click="onClickPopDelete">삭제</button> -->
      </div>
    </Sticky>
    <template v-slot:popup> </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import TableHead from "@/components/shared/TableHead";
import ConnectView from "@/components/common/connect/ConnectView";
import Sticky from "@/components/layout/content/Sticky.vue";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import Input from "@/components/common/input/Input";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import PerformanceViewModel from "@/pages/admin/performance/PerformanceViewModel";
import PerformanceRadio from "@/components/admin/performance/write/PerformanceRadio";

export default {
  name: "PerformanceView",
  components: {
    PerformanceRadio,
    PageWithLayout,
    TableViewWithTitle,
    Input,
    TableHead,
    ConnectView,
    Sticky,
    AlertPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      evaluateNum: null,
      userNum: null,
      inspectId: null,
      model: new PerformanceViewModel(),
      itemData: {},
      evaluateData: {},
    };
  },
  computed: {},
  async beforeMount() {
    const { id } = this.$route.params;
    this.evaluateNum = id;
    const data = await this.getEvaluateData(id);
    this.evaluateData = data;
    const templateId = data.templateId;
    const templateData = templateId ? await this.getPerformanceTemplateData(templateId) : {};
    this.itemData = data;
    this.model.setData(templateData, data, {});
  },
  methods: {
    async getEvaluateData(evaluateNum) {
      const path = `${this.$apiPath.ST_EVALUATE_EVALUATE}/${evaluateNum}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        return {};
      }

      return result.data;
    },
    async getPerformanceTemplateData(templateId) {
      const path = `${this.$apiPath.ST_EVALUATE}/${templateId}`;
      const result = await ApiService.shared.getData(path);
      if (!result.data) {
        return {};
      }
      return result.data;
    },
    onClickGoToList() {
      this.$router.push({
        path: this.$routerPath.SYSTEM_PERFORMANCE_PRESENT,
        query: this.$route.query,
      });
    },
    onClickModify() {
      this.$router.push({
        path: `${this.$routerPath.PERFORMANCE_MODIFY}/${this.evaluateNum}`,
      });
    },
  },
};
</script>
