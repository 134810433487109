export default class PerformanceViewModel {
  constructor() {
    this.partnerCutOffPoint = 0;
    this.totalScore = 0;
    this.questList = [];
    this.evaluateNum = "";
    this.evaluateName = "";
    this.regDate = "";
    this.regId = "";
    this.title = "";
  }

  setData(templateData, evaluateData, rowData) {
    this.title = rowData.title;
    this.evaluateNum = evaluateData.evaluateNum;
    this.evaluateName = evaluateData.evaluateName;
    this.regDate = evaluateData.regDate;
    this.regId = evaluateData.regId;
    this.totalScore = evaluateData.totalScore;
    this.partnerCutOffPoint = evaluateData.partnerCutOffPoint;
    this.questList = templateData.questList;

    this.questList.forEach((item) => {
      if (evaluateData.answerList && evaluateData.answerList.length > 0) {
        // 수정
        const idx = evaluateData.answerList.findIndex((answer) => {
          return answer.questNum === item.questNum;
        });
        if (idx > -1) {
          const optionType =
            item.optionList && item.optionList.length > 0 ? item.optionList[0].optionType : "";
          item.optionType = optionType;
          item.value =
            optionType === "M"
              ? evaluateData.answerList[idx].selection
              : evaluateData.answerList[idx].optionNum;
          item.optionNum = evaluateData.answerList[idx].optionNum;
          item.points = evaluateData.answerList[idx].points;
          item.selection = evaluateData.answerList[idx].selection;
        }
      }
    });
  }
}
